<template>
  <div>
    <v-divider></v-divider>
    <v-sheet class="pt-4 pl-4 pa-2">
      <h3 v-if="(!sideBarHidden && !initLoading) || $vuetify.breakpoint.mobile">
        {{ folderName }}
      </h3>
      <v-icon v-else>mdi-folder</v-icon>
    </v-sheet>
    <v-divider></v-divider>
    <v-list dense>
      <sidebar-item
        v-for="folder in getFolders"
        :key="folder.id"
        :folder="folder"
      >
      </sidebar-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: { sidebarItem: () => import("./SideBarItem.vue") },
  computed: {
    ...mapGetters("presentation", [
      "headFolderName",
      "sideBarHidden",
      "getFolders",
      "initLoading",
    ]),
    folderName() {
      if (this.headFolderName) return this.headFolderName
      else return 'Library';
    }
  },
};
</script>
